// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        this.load()
        if (this.data.has("refreshInterval")) {
            this.startRefreshing()
        }
    }

    disconnect() {
        this.stopRefreshing()
    }

    startRefreshing() {
        this.refreshTimer = setInterval(() => {
            this.load()
        }, this.data.get("refreshInterval"))
    }

    stopRefreshing() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer)
        }
    }

    load() {
        let controller = this
        fetch(this.data.get("url"))
            .then(response => response.json())
            .then(json => {
                let diff = json.messages.length - this.data.get("message-count")
                if(diff > 0){
                    let wrapper = this.element.querySelector("div")
                    $('#conversation-alert-wrapper').show()
                    //controller.style.setProperty('display', 'block')
                    let alertMessage = wrapper.querySelector('#alert-message')
                    alertMessage.innerHTML = `${diff} neue Nachricht(en)`
                }
            })
    }
}
