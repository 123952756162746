import { Controller } from "stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this)
        })
    }

    end(event){
        let drag_url = event.item.getAttribute("data-drag-url");

        const data = {
            position: event.newIndex + 1
        };
        const options = {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
            }
        }
        fetch(drag_url, options)

        /*Rails.ajax({
            url: drag_url,
            type: "PATCH",
            data: data
        })*/
    }
}
